<template>
<div id="studentDetails" v-if="StudentData != undefined">

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Student Details
    </p>
  </div>
</div>  

<!-- profile pic -->
<v-container class="py-5" fluid style="margin-top: -200px">
<v-card class="py-5 elevation-5">
<v-speed-dial v-model="fab" absolute top right direction="bottom" transition="slide-y-reverse-transition">
    <template v-slot:activator>
        <v-btn v-model="fab" color="pink" class="elevation-10" dark fab >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-settings</v-icon>
        </v-btn>
    </template>
    <v-btn color="green" small dark fab  @click="$router.push('/editstudent/' + StudentId)"><v-icon>mdi-pencil</v-icon></v-btn>
    <v-btn color="red" small dark fab @click="deleteDialog = true"><v-icon>mdi-delete</v-icon></v-btn>
</v-speed-dial>
<v-row class="mt-3">
<v-col cols="12" sm="12" md="4" class="d-flex align-center justify-center">
<div id="ProfileCard">
    <div class="white animated fadeIn slower pa-3 mb-5 d-flex elevation-5 justify-center align-center" style="height: 170px;width: 170px; border-radius: 100px">

    <v-img v-if="!StudentData.imageUrl" class="elevation-8" src="../../assets/images/profile-pic.svg" alt="Profile Pic" style="height: 150px; width: 150px; border-radius: 100px" rel="preload"></v-img>
    <v-img v-else :src="StudentData.imageUrl" class="elevation-8" alt="Profile Pic" style="height: 150px; width: 150px; border-radius: 100px" rel="preload">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    </div>      
</div>
</v-col>
<v-col cols="12" sm="12" md="8">
    
  <div class="pa-5">

  <p class="title mb-1 mt-5">Id : {{StudentData.studentID}}</p>        
  <p class="title mb-1">Name : {{StudentData.name}}</p>        
  <p class="title mb-1">Course : {{StudentData.course}}</p>    

  <div class="mt-4">
  <v-btn rounded class="primary elevation-10 px-3 my-3 mr-2" @click="$router.push('/marks/' + StudentId)">
   <v-icon class="mr-2"> mdi-square-root mdi-24px </v-icon> Marks
  </v-btn>
  <v-btn rounded class="success elevation-10 px-3 my-3 mr-2" @click="$router.push('/giveaward/' + StudentId)">
   <v-icon class="mr-2"> mdi-trophy-outline mdi-18px </v-icon> Awards
  </v-btn>
  <v-btn rounded class="info elevation-10 darken-2 px-3 my-3 mr-2" @click="$router.push('/marks/' + StudentId)">
   <v-icon class="mr-2"> mdi-account-check mdi-24px </v-icon> Attendance
  </v-btn>
  </div>

  </div>

</v-col>
</v-row>

</v-card>
</v-container>

<div class="py-5 d-flex align-center justify-center ma-3">
<v-row>

<!-- personal details -->
<v-col cols="12" sm="6" md="6" height="100%">

<v-card class="elevation-5 ma-0 pa-5" width="100%">
<v-card-title>
<p class="headline">Personal Details</p>
</v-card-title>
<table>
    <tr><td class="subtitle-1">Id : </td><td>{{ StudentData.studentID }}</td></tr>
    <tr><td class="subtitle-1">Name : </td><td>{{ StudentData.name }}</td></tr>
    <tr><td class="subtitle-1">Class : </td><td>{{ StudentData.class }}</td></tr>
    <tr><td class="subtitle-1">Syllabus : </td><td>{{ StudentData.syllabus }}</td></tr>
    <tr><td class="subtitle-1">School : </td><td>{{ StudentData.school }}</td></tr>
    <tr><td class="subtitle-1">Course : </td><td>{{ StudentData.course }}</td></tr>
    <tr v-if="StudentData.subjects.length != 0">
        <td class="subtitle-1">Subjects : </td>
        <td>{{StudentData.subjects.toString()}}</td>
    </tr>
    <tr><td class="subtitle-1">Date Of Birth : </td><td>{{ StudentData.DOB }}</td></tr>
    <tr><td class="subtitle-1">Date Of Join : </td><td>{{ StudentData.DOJ }}</td></tr>
    <tr><td class="subtitle-1">Gender : </td><td>{{ StudentData.gender }}</td></tr>
    <tr><td class="subtitle-1">Father's Name : </td><td>{{ StudentData.fatherName }}</td></tr>
    <tr><td class="subtitle-1">Father's Job : </td><td>{{ StudentData.fatherOccupation }}</td></tr>
    <tr><td class="subtitle-1">Father's Mob : </td><td>{{ StudentData.fatherMob }}</td></tr>
    <tr><td class="subtitle-1">Mother's Name : </td><td>{{ StudentData.motherName }}</td></tr>
    <tr><td class="subtitle-1">Mother's Job : </td><td>{{ StudentData.motherOccupation }}</td></tr>
    <tr><td class="subtitle-1">Mother's Mob : </td><td>{{ StudentData.motherMob }}</td></tr>
    <tr><td class="subtitle-1">Address : </td><td>{{ StudentData.address }}</td></tr>
    <tr><td class="subtitle-1">Status : </td><td>{{ StudentData.status }}</td></tr>
</table>
</v-card>

</v-col>

<!-- add. info -->
<v-col cols="12" sm="6" md="6" height="100%">
    <div height="100%"></div>
</v-col>

</v-row>
</div>

    
<!-- delete modal -->
<v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
<v-card>
<v-card-title class="headline">Confirm Delete?</v-card-title>
<v-card-text>
    This action can't be undone!
</v-card-text>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
    <v-btn color="primary" text @click="DeleteStudent()">Delete</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<!-- delete dialog ends -->
</div>
</template>

<script>
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

export default {

    name: 'StudentDetails',

    computed: {

        StudentId(){         
          return this.$route.params.id != null ? this.$route.params.id : null;
        },

        StudentData(){
            if(this.StudentId != null) {
                let data =  this.$store.getters.GetStudentWithID(this.StudentId);
                if(data != undefined) { 
                    if(data.length != 0){return data[0]}else{this.$router.push("/students");}
                }else{this.$router.push("/students");}
            }else{this.$router.push("/students"); }             
        }
        
    },

    data(){return{

        deleteDialog: false,fab: false,
    }},

    methods: {

        async DeleteStudent(){
            this.deleteDialog = false;
            // get image url
            let url;
            let sid = Number(this.StudentId);
            if(this.StudentData.imageUrl){
                url = this.StudentData.imageUrl.toString();
            }
            // get document where student id equals selected one
            await firebase.firestore().collection("studentDetails")
            .where("studentID", "==", sid).get().then(
            (doc) => { if(doc.docs[0].id){
                // update all datas(sorry for cramping it up)
                firebase.firestore().collection("studentDetails").doc(doc.docs[0].id).delete()
                .then(
                (result) => { 
                   // delete image    
                   if(url){
                        firebase.storage().refFromURL(url).delete();
                    }
                    // batch delete student awards
                    this.BatchDelete(sid);
                });}       
            }).catch((err) => { console.log(err);  }); 
        },

         // batch delete awards from all db
        async BatchDelete(sid){
            // delete all award traces
            // First perform the query
            await firebase.firestore().collection("studentAwards").where("studentID","==", sid).get()
            .then((querySnapshot) => {

                if(querySnapshot.docs.length != 0){

                    // Once we get the results, begin a batch
                    var batch = firebase.firestore().batch();
                    querySnapshot.forEach((doc) => {
                        // For each doc, add a delete operation to the batch
                        batch.delete(doc.ref);
                    });
                    // Commit the batch
                    batch.commit().then((s) => {this.$router.push('/students');});

                }else{this.$router.push('/students');}

            });
        },
    }
}
</script>

<style scoped>
table{
    border-spacing: 10px;
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
}
table td{
    padding: 10px 10px;
}
</style>